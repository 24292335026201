.middle-column {
  margin: 10% auto;
  width: 50%;
  text-align: center;
  color: $default-white;
  #name {
    margin-top: 0;
    font-size: 3rem;
  }

  a {
    color: $default-white;
    &:hover {
      color: $primary-color;
    }
  }
}

.page-links {
  font-size: 1.5rem;
  a {
    color: $default-white;
    margin: 0 1rem 0 1rem;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
    transition: color ease-in 0.2s;
  }
}
