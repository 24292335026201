@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-50%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(0%) translateZ(0) scaleY(1);
  }
}
.waveWrapper {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
}
.bgTop {
  z-index: 15;
  opacity: 0.5;
}
.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}
.bgBottom {
  z-index: 5;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-size: 100% 30vh;
  background-image: url("../../images/waves/waveTop.svg");
}
.waveAnimation .waveTop {
  animation: move_wave 10s ease-in-out infinite;
  -webkit-animation: move_wave 10s linear infinite;
}
.waveMiddle {
  background-size: 100% 30vh;
  background-image: url("../../images/waves/waveMiddle.svg");
}
.waveAnimation .waveMiddle {
  animation: move_wave 16s linear infinite;
}
.waveBottom {
  background-size: 100% 30vh;
  background-image: url("../../images/waves/waveBottom.svg");
}
.waveAnimation .waveBottom {
  animation: move_wave 21s linear infinite;
}
